body {
  background-color: #f5f6fa !important;
}

.login {
  text-align: center;
  padding: 1em !important;
  margin: auto !important;
  width: 600px !important;
  margin-top: 100px !important;
}

.login .form .row {
  padding-top: 20px !important;
}

.login .form .row h2 {
  color: black !important;
}

.error-message {
  color: red !important;
  margin: 0 !important;
  padding-top: 10px !important;
}


.login .footer, .login .copyright {
  width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin: auto !important;
  text-align: center !important;
}

label {
  display: flex;
  font-weight: 600;
}

button {
  justify-content: flex-end;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
