body{
  font-family: Helvetica, sans-serif;
}
a{
  text-decoration: none !important;
  color: #0d39aa !important;
}

/* This is a fix for checkboxes in forms being not properly aligned with their labels. See Issue #1.
Using the :has Selector would be more elegant but it is not as of yet supported in the newest 
Firefox version 103 unless the user has enabled the feature manually. https://developer.mozilla.org/en-US/docs/Web/CSS/:has */
[class^="col-"]:nth-child(n) {
    display: flex;
    align-items: center;
}

[class^="col-"] > .form-check-input{
    margin-top: 0;
}
