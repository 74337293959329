.select-input {
  padding: 6px 3px;
  color: #222a42;
  user-select: none;
  background-color: white;
  border: 1px solid rgba(29, 37, 59, 0.5);
  border-radius: 0.25rem;
  cursor: default;
}

.select-input:focus {
  border-color: red;
  box-shadow: 0px 0px 0px 4px rgb(191, 217, 255);
}

.select-modal {
  position: fixed;
  z-index: 1;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 2px 1px lightgray;
  background-color: whitesmoke;
  color: black;
  cursor: default;
  user-select: none;
  max-height: 300px;
  overflow-y: scroll;
}

.select-options {
  padding: 2px;
}

.select-option {
  padding: 2px;
  border-radius: 0.25rem;
}

.select-option:focus {
  background-color: lightgray;
}

.column {
  display: inline-block;
  vertical-align: middle;
}