#dummy-input {
  position: fixed;
  width: 0;
  border: 0;
  padding: 0
}

#dummy-input:focus {
  outline: none;
}

.btn-minimal:disabled {
  color: gray;
}

.btn-minimal:disabled:hover {
  color: gray;
  cursor: default;
}

.cy-contextmenu {
  border-radius: 0.25rem;
  background-color: whitesmoke;
}

.cy-contextmenu .cy-contextmenu {
  max-height: 400px;
  overflow-y: scroll;
}

.cy-contextmenu-item {
  border-radius: 0.25rem;
  font-size: 15px;
  padding: 3px 24px;
}

.cy-contextmenu-item:hover {
  color: black;
  background-color: lightgray;
}

#cy-resize {
  height: 15px;
  padding: 0;
  width: 100%;
}

#cy-resize:hover {
  cursor: row-resize;
}

.spinner-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.spinner-border {
  width: 80px !important;
  height: 80px !important;
  color: #1d253b;
}

#info-bar {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  box-shadow: -4px 0 10px 1px gray;
  background-color: white;
}

.minimized {
  width: 25px;
  overflow-y: hidden !important;
}

#btn-expand {
  height: 100%;
  width: 100%;
  padding: 15px 0px !important;
  writing-mode: sideways-lr;
  display: inline-flex;
}

.minimized h6 {
  flex: auto;
  width: 25px;
  padding-left: 5px;
  margin: 0;
}

#btn-minimize {
  width: 100%;
  padding: 0;
}

#btn-minimize h6 {
  float: left !important;
}

#btn-minimize > svg {
  float: right;
}

#info-bar-content-wrapper {
  height: 100%;
  overflow-y: scroll;
}

#info-bar-content {
  padding: 15px 10px 15px 0px;
}

.graph-link {
  padding: 0 !important;
  text-align: left;
  vertical-align: top;
}

.graph-link-list .graph-link {
  font-size: 16px;
  transform: translate(0px, 2px);
}

#info-bar-resize {
  float: left;
  width: 10px;
  height: 100%;
}

#info-bar-resize:hover {
  cursor: col-resize;
}