.navbar{
  padding-left: 280px !important;
}

.navbar > .container-fluid{
  padding: 0px !important;
}

.search-bar{
  width: 600px !important;
  margin-right: 5px !important;
  margin-left: 0px !important;
}

#btn-close-search{
  position: absolute !important;
  margin-left: 570px !important;
  margin-top: 3px !important;
}

#modal-search{
  position: absolute !important;
  max-height: 500px !important;
  background-color: white !important;
  margin: 0px !important;
  width: 600px !important;
  overflow: scroll !important;
  border-style: solid !important;
  border-color: gray !important;
  border-width: 1px !important;
  border-radius: 0.25rem !important;
  box-shadow: inset !important;
  margin-top: 43px !important;
  box-shadow: 3px 3px 1px lightgray !important;
}

#modal-search a{
  margin: 0px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

#modal-search .search-result-category-wrapper{
  padding-top: 5px !important;
}

#modal-search .search-result-category{
  border-bottom: 1px solid gray; 
  line-height: 0em;
  margin: 15px 12px;
  padding-left: 20px;
}

#modal-search .search-result-category span{
  background:#fff; 
  padding:0 10px; 
  color: gray
}

#modal-search .search-result :hover{
  background-color: lightgray !important;
}

.search-link{
  display: inline-block !important;
  width: 100% !important;
  height: 100% !important;
  padding-left: 24px !important;
}

.navbar-btn {
  padding: 0 !important;
  width: 38px;
  height: 38px;
}

.navbar-btn a {
  display: block;
  width: 38px;
  height: 38px;
  padding: 6px 12px;
}

.navbar-btn a svg {
  color: white !important;
}