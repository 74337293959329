#index li {
  color: #0d39aa;
}

#index li:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}