p {
    font-size: 1.5vh !important;
    color: black !important;
    /* overflow to new line */
    max-width: 100%;
    white-space: initial !important;
    text-transform: none !important;
    line-height: 170% !important;
    /* overflow-wrap: break-word; */
}

hr.solid {
    /* width: 75%; */
    /* border: 0; */
    /* border-top-color: black;
    border-top-width: 5px; */
    border-top: 1px solid #bbb;
    margin: 0px 0px 0px 0px !important;
    background-color: #bbb;
    opacity: 1;
}

.nav-link {
    max-width: 100%;
    overflow-x: hidden;
    padding: 5px 0px 0px 10px !important;
}

.category {
  background-color: lightgray;
  margin: 10px 15px 0;
}

.category p {
    font-size: 1.5vh !important;
    font-weight: bold;
}

#current-device {
  text-align: center;
  margin: 0 5px 10px;
  padding: 0 !important;
  background-color: rgb(133, 209, 204);
}

#current-device a {
  margin: 0 !important;
  padding: 0 !important;
}

#current-device a p {
  padding: 4px 10px !important;
  color: black !important;
  font-size: large !important;
  font-weight: bold;
}

li {
    /* max-width: 100%; */
}

.sidebar .sidebar-wrapper-wrapper {
  height: 100%;
  padding-bottom: 140px;
}

.sidebar .sidebar-wrapper {
  direction: rtl;
  /* max-width: 100%; */
  /* overflow-x: hidden; */
}

#sidebar-nav {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar[data=white],
.off-canvas-sidebar[data=white] {
  background: white;
  /* color: black !important; */
  /* background: -webkit-linear-gradient(0deg, #0098f0 0%, #00f2c3 100%);
  background: -o-linear-gradient(0deg, #0098f0 0%, #00f2c3 100%);
  background: -moz-linear-gradient(0deg, #0098f0 0%, #00f2c3 100%);
  background: linear-gradient(0deg, #0098f0 0%, #00f2c3 100%); */
}

.submenu {
  position: fixed !important;
  transform: translate(0px, -10px) !important;
  margin-left: 215px !important;
  width: 300px !important;
  max-height: 500px !important;
  overflow-y: scroll !important;
  background-color: whitesmoke !important;
  border-radius: 0.25rem !important;
  box-shadow: 5px 5px 2px lightgray !important;
}

.all-devices {
  background-color: rgb(133, 209, 204) !important;
  margin-left: 225px !important;
}

.all-devices li {
  cursor: pointer;
  margin: 5px 15px;
}

.all-devices li :hover {
  font-weight: bold;
}

.all-devices p {
  padding: 5px 10px;
  font-size: large !important;
}

.sidebar-wrapper > .nav {
  direction: ltr;
}

.sidebar-wrapper > .nav .nav-link {
  padding: 2px 10px 2px 10px !important;
  border-radius: 0.25rem !important;
}

.submenu > .nav {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.submenu .navlink-list :hover {
  background-color: lightgray !important;
}

.navlink-list :hover {
  background-color: whitesmoke !important;
}

.category-div {
  margin-top: 10px;
}

#autotech-logo {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#i11-logo {
  position: absolute;
  bottom: 0 !important;
}